export const courses = [
  {
    title: "Fashion Design",
    tutor: "By Muhammad",
    subtext:
      "Master the art of fashion and design, and create stunning trends with our expert guidance.",
  },
  {
    title: "Photography",
    tutor: "By Dorota",

    subtext:
      "Capture your essence, tell your unique story, and unveil your world through the lens of our expert photography courses.",
  },
  {
    title: "Makeup Artistry",
    tutor: "By Sakina",

    subtext:
      "Enhance your features, express your beauty, and master the art of makeup with our seasoned professionals.",
  },
  {
    title: "Music Instruments",
    tutor: "By Ali",

    subtext:
      "Create melodies, evoke emotions, and embrace the magic of music with our instrument-focused courses.",
  },
  {
    title: "Indian Dance",
    tutor: "By Aarna",

    subtext:
      "Move to the rhythm, celebrate tradition, and immerse yourself in the rich cultural tapestry of Indian dance forms.",
  },
  {
    title: "Afro Dance",
    tutor: "By Stafford",

    subtext:
      "Feel the beat, embrace the culture, and let the rhythms of Afro dance ignite your passion for movement and tradition.",
  },
  {
    title: "Modelling",
    tutor: "By Claire",

    subtext:
      "Step into the spotlight, strike a pose, and embody the epitome of elegance and style with our expert guidance.",
  },
  {
    title: "Crotcheting",
    tutor: "By Lydia",

    subtext:
      "Master the art of crocheting and create beautiful handmade designs, exploring various stitches and textile techniques.",
  },
  {
    title: "Journalism",
    tutor: "By Jacqueline",

    subtext:
      "Refine your storytelling, and report news with integrity and impact under the guidance of experienced journalists.",
  },
  {
    title: "Acting",
    tutor: "By Gary",

    subtext:
      "Embrace the stage, embody characters, and convey emotions authentically through our comprehensive classes.",
  },
  {
    title: "Home work and Tuition",
    tutor: "",

    subtext:
      "Navigate academics with ease. Our dedicated support ensures homework is a breeze and knowledge is nurtured.",
  },
];

export const colors = [
  "bg-yellow-400",
  "bg-orange-400",
  "bg-gray-400",
  "bg-green-400",
  "bg-purple-400",
  "bg-cyan-400",
  "bg-pink-400",
  "bg-lime-500",
  "bg-fuchsia-400",
  "bg-amber-400",
  "bg-rose-400",
];
