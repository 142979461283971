import React from "react";

import { Link } from "react-router-dom";
import team1 from "../assets/images/team/1.jpg";
import team2 from "../assets/images/team/2.jpg";
import team3 from "../assets/images/team/3.jpg";
import team4 from "../assets/images/team/4.jpg";
import team5 from "../assets/images/team/5.jpg";
import team6 from "../assets/images/team/6.jpg";
import team7 from "../assets/images/team/7.jpg";
import team8 from "../assets/images/team/8.jpg";
import team9 from "../assets/images/team/9.jpg";
import team12 from "../assets/images/team/12.jpg";
import team13 from "../assets/images/team/13.jpg";
import team14 from "../assets/images/team/14.jpg";
import team15 from "../assets/images/team/15.jpg";
export default function Team() {
  const team = [
    {
      name: "Lydia Cutler",
      profile: team1,
      designation: "Managing Director",
    },
    {
      name: "Halton Cutler",
      profile: team2,
      designation: "Marketing Director",
    },
    {
      name: "Claire Louiser",
      profile: team9,
      designation: "Models Director",
    },
    {
      name: "Muhammad Fawad Noori",
      profile: team3,
      designation: "Fashion Design Lecturer",
    },
    {
      name: "Dorota Puszkiewicz",
      profile: team4,
      designation: "Photography Lecturer",
    },

    {
      name: "Sakina Zeynalova",
      profile: team6,
      designation: "Makeup Artistry Trainer",
    },
    {
      name: "Angela Thouless",
      profile: team7,
      designation: "Art Lecturer",
    },
    {
      name: "Ali Campbell",
      profile: team8,
      designation: "Music Instruments Trainer",
    },
    {
      name: "Stafford William",
      profile: team12,
      designation: "Afro Dance Instructor / Creative Dance Director",
    },
    {
      name: "Jacqueline Wake Young",
      profile: team13,
      designation: "Journalism Instructor",
    },   
     {
      name: "Andrew Crouse",
      profile: team14,
      designation: "Acting Classes",
    },  {
      name: "Ulianka Makisymiuk",
      profile: team15,
      designation: "Model Trainer",
    },
    
  ];

  return (
    <>
      <section className="relative md:py-24 py-16 " id="team">
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-orange-600 text-base font-medium uppercase mb-2">
              Our Team
            </h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
              Creative Minds
            </h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
              Discover the driving force behind Fashion Art Media Academy: a
              team passionate about igniting creative potential and shaping the
              next generation of artistic talent. We are committed to guiding
              and inspiring, dedicated to the art of education.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-6 mt-8">
            {team.map((member, index) => (
              <div
                key={index}
                className="relative rounded-md shadow-lg overflow-hidden group"
              >
                <img
                  src={member.profile}
                  className="group-hover:origin-center group-hover:scale-105 transition duration-500"
                  alt=""
                />
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 z-0 transition duration-500"></div>

                <div className="content absolute start-4 end-4 bottom-4 bg-white dark:bg-slate-900 opacity-0 group-hover:opacity-100 p-4 rounded-md text-center transition-all duration-500">
                  <Link
                    to="#"
                    className="h5 text-md dark:text-white hover:text-orange-600 dark:hover:text-orange-600 font-medium"
                  >
                    {member.name}
                  </Link>
                  <h6 className="text-slate-400 dark:text-slate-300 mb-0 font-light">
                    {member.designation}
                  </h6>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <!--end container--> */}
      </section>
    </>
  );
}
