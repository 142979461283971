import { Link } from "react-router-dom";
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import StrategyImage from "../assets/images/svg/design-thinking.svg";
import departmentImage from "../assets/images/svg/coding.svg";
import launchImage from "../assets/images/svg/office-desk.svg";
import { courses, colors } from "../data/courses";
import React, { useState, useEffect } from "react";
const CLIENT_ID =
  "Aeo_qH1SR34ON3kdo2ehX1aV4sosGj_OBoRpqaKb3PMwryphhzKJLM8e_HPZUSvXf2vmWKNyE7xeTqrP";

export default function Services() {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Course",
            amount: {
              currency_code: "EUR",
              value: 10,
            },
          },
        ],
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(() => {
    if (success) {
      alert("Payment successful!!");
      console.log("Order successful . Your order id is--", orderID);
    }
  }, [success]);

  return (
    <>
      
      <section className="relative md:py-24 py-16 active" id="features">
        <div className="container lg mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
            <div>
              <h6 className="text-orange-600 text-base font-medium uppercase mb-2">
                Our Courses
              </h6>
              <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">
                {" "}
                Unleash your potential and
                <br /> refine your skills
              </h3>
            </div>
            <div>
              <p className="text-slate-400 dark:text-slate-300 max-w-xl">
                Explore creativity and expertise at Fashion Art Media Academy.
                Refine your skills in fashion, art, and media.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
            {courses.map((item, key) => (
              <div
                key={key}
                className={`features p-6 ${colors[key]} ${
                  key % 2 === 0
                    ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800 "
                    : "shadow-xl shadow-slate-100 dark:shadow-slate-800"
                } transition duration-500 rounded-3xl mt-8 relative`}
              >
                {/* <div className="absolute top-4 right-4 bg-black/40  text-white w-fit text-xs px-2 py-1 font-semibold rounded-md">£ 10 ONLY</div> */}
                <div className="content mt-7">
                  <p className="text-lg hover:text-orange-600  dark:text-black/80 dark:hover:text-orange-600 transition-all duration-500 ease-in-out font-medium">
                    {item.title} Classes
                  </p>
                  <p className="text-slate-600 text-sm">{item.tutor}</p>
                  <p className="text-slate-100 mt-3">{item.subtext}</p>
                  <div className="my-4 flex gap-5 items-center">
                  <a
                    href="#" className="nav-item bg-white font-semibold text-xs uppercase tracking-wide dark:text-black/80 px-3 py-2 rounded-sm shadow-2xl">Book Class</a>
                    {/* <button
                      onClick={() => setShow(!show)}
                      className="nav-item bg-white font-semibold text-xs uppercase tracking-wide dark:text-black/80 px-3 py-2 rounded-sm shadow-2xl"
                    >
                      Book Class
                    </button>
                    {show ? (
                      <div className="flex gap-2 items-center">
                        <p className="text-xs text-white py-1 italic">
                          Continue with
                        </p>
                        <PayPalButtons
                          style={{
                            layout: "horizontal",
                            shape: "rect",
                            disableMaxWidth: true,
                            color: "silver",
                            tagline: false,
                            height: 30,
                          }}
                          createOrder={createOrder}
                          onApprove={onApprove}
                        />
                      </div>
                    ) : null} */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

 
   </> 
  );
  
}
