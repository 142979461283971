import React, { useState } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";
import LogoLight from "../assets/images/logo-light.png";
import LogoDark from "../assets/images/logo-dark.png";
import Typed from "typed.js";
import { Functions, Client } from "appwrite";
import BackgroudImage from "../assets/images/bg/1.jpg";
import Services from "../component/Services";
import About from "../component/About";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Switcher from "../component/Switcher";
import Team from "../component/Team";
import { courses } from "../data/courses";
const classes = [
  "Fashion Design",
  "Photography",
  "Makeup Artistry",
  "Music Instruments",
  "Indian Dance",
  "Afro Dance",
  "Modelling",
  "Crotcheting",
  "Home work and Tuition",
  "Journalism"
];

/**
 * Index Component
 */
export default function Index() {
  const client = new Client()
    .setEndpoint(process.env.REACT_APP_PROJECT_URL)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const functions = new Functions(client);

  const [isOpen, setMenu] = useState(true);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [book, setBook] = useState({
    name: "",
    email: "",
    phone: "",
    class: "Fashion Design",
  });
  window.addEventListener("scroll", windowScroll);

  const toggleMenu = () => {
    setMenu(!isOpen);
  };

  setTimeout(() => {
    new Typed("#typed", {
      strings: [
        "<b>Fashion</b>",
        "<b>Art</b>",
        "<b>Media</b>",
        "<b>Design</b>",
        "<b>Modelling</b>",
        "<b>Acting</b>",
        "<b>Journalism</b>",
      ],
      backDelay: 2000,
      loop: true,
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
    });
  }, 500);


  const sendMail = async () => {
    setLoading(true);
    try {
      const execution = await functions
        .createExecution(
          process.env.REACT_APP_FUNCTION_ID,
          JSON.stringify(book),
          false
        )
        .then((response) => {
          setLoading(false);
          setShow(true);
          setBook({
            name: "",
            email: "",
            phone: "",
            class: "Fashion Design",
          });
          setTimeout(() => setShow(false), 3000);
        });
    } catch (err) {
      setLoading(false);
      // console.error(err.message);
    }
  };

  /**
   * Window scroll
   */
  function windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
    } else {
      navbar.classList.remove("is-sticky");
    }
  }

  return (
    <>
      <div>
        <nav className="navbar" id="navbar">
          <div className="container flex flex-wrap items-center justify-end">
            <a className="navbar-brand" href="index.html">
              <span className="inline-block dark:hidden">
                <img src={LogoDark} className="l-dark" alt="" />
                <img src={LogoLight} className="l-light" alt="" />
              </span>
              <img
                src={LogoLight}
                className="hidden dark:inline-block"
                alt=""
              />
            </a>
            <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
              <ul className="list-none menu-social mb-0">
                <li className="inline ms-1">
                  <Link2 to="#">
                    <span className="login-btn-primary">
                      <span className="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white">
                        <i className="uil uil-twitter"></i>
                      </span>
                    </span>
                    <span className="login-btn-light">
                      <span className="btn btn-sm btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                        <i className="uil uil-twitter"></i>
                      </span>
                    </span>
                  </Link2>
                </li>
                <li className="inline ms-1">
                  <Link2 to="#">
                    <span className="login-btn-primary">
                      <span className="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white">
                        <i className="uil uil-instagram"></i>
                      </span>
                    </span>
                    <span className="login-btn-light">
                      <span className="btn btn-sm btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                        <i className="uil uil-instagram"></i>
                      </span>
                    </span>
                  </Link2>
                </li>
              </ul>
              <button
                type="button"
                className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                onClick={toggleMenu}
              >
                <span className="sr-only">Navigation Menu</span>
                <i className="mdi mdi-menu mdi-24px"></i>
              </button>
            </div>

            <div
              className={`${
                isOpen === true ? "hidden" : "block"
              } navigation lg_992:order-1 lg_992:flex`}
              id="menu-collapse"
            >
              <ul className="navbar-nav nav-light" id="navbar-navlist">
                <Link
                  className="nav-item"
                  to="home"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Home</span>
                </Link>
                <Link
                  className="nav-item"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  to="about"
                >
                  <span className="nav-link">About us</span>
                </Link>
                <Link
                  className="nav-item"
                  to="features"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Services</span>
                </Link>

                <Link
                  className="nav-item"
                  to="team"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Team</span>
                </Link>
                <Link
                  className="nav-item"
                  to="contact"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Contact us</span>
                </Link>
              </ul>
            </div>
          </div>
        </nav>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-36 lg:py-72 lg:pb-0 md:pb-8 w-full table relative bg-[url('../../assets/images/bg/2.jpg')] bg-center bg-cover"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">
            <div className="grid lg:grid-cols-12 mt-10 md:grid-cols-2 gap-2">
              <div className="lg:col-span-7">
                {/* <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                  Having fun in the
                  <br /> world of Creativity
                </h4> */}

                <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                Cultivating Creative Minds <br />
                In the World of{" "}
                <span
                  className="typewrite relative text-type-element"
                  id="typed"
                  data-period="2000"
                  data-type='[ "Fashion", "Art", "Media", "Design" ]'
                ></span>
              </h4>
                <p className="text-white opacity-50 mb-0 max-w-2xl text-lg">
                  Gain confidence, join our classes and master classes. Be
                  fierce; in daily life, on the runway, creating wonderful
                  designs, styling, make up expert, in acting, dancing and many
                  more classes.
                </p>
              </div>
              <div className="lg:col-span-5 mt-8 md:mt-0">
                <div className="bg-white dark:bg-slate-900 rounded-xl shadow md:p-10 p-6 lg:ms-10 z-10 relative">
                  <h4 className="mb-5 font-medium text-2xl dark:text-white">
                    Start your Classes Today
                  </h4>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      sendMail();
                    }}
                  >
                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <label className="dark:text-white">Name</label>
                        <input
                          name="name"
                          id="fName"
                          type="text"
                          className="form-input mt-2"
                          placeholder="Name :"
                          required
                          value={book.name}
                          onChange={(e) => {
                            setBook({ ...book, name: e.target.value });
                          }}
                        />
                      </div>
                      <div className="mb-5">
                        <label className="dark:text-white">Email</label>
                        <input
                          name="email"
                          id="yEmail"
                          type="email"
                          className="form-input mt-2"
                          placeholder="Email :"
                          required
                          value={book.email}
                          onChange={(e) => {
                            setBook({ ...book, email: e.target.value });
                          }}
                        />
                      </div>
                      <div className="mb-5">
                        <label className="dark:text-white">Phone No.</label>
                        <input
                          name="number"
                          id="phNumber"
                          className="form-input mt-2"
                          placeholder="+12 12458 854"
                          value={book.phone}
                          required
                          onChange={(e) => {
                            setBook({ ...book, phone: e.target.value });
                          }}
                        />
                      </div>{" "}
                      <div className="mb-5 flex flex-col w-fit ">
                        <label className="dark:text-white">Class</label>
                        <select
                          className="form-select border p-3  rounded-md mt-2 "
                          onChange={(e) => {
                            setBook({ ...book, class: e.target.value });
                          }}
                        >
                          {courses.map((service, index) => (
                            <option className="text-lg" key={index}>
                              {service.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      {show ? (
                        <p className="text-green-600 font-medium py-2">
                          Application sent Successfully!
                        </p>
                      ) : (
                        <></>
                      )}
                      <button
                        type="submit"
                        to="/"
                        className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md w-full"
                      >
                        {loading ? "Sending application..." : "Book Class"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="relative">
          <div className="shape overflow-hidden text-gray-50 dark:text-slate-800">
            <svg
              viewBox="0 0 2880 250"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M720 125L2160 0H2880V250H0V125H720Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        {/* About section */}
        <About />

        {/* Service section */}
        <Services />

        {/* Portfolio section */}
        {/* <Portfolio /> */}

        {/* Review section */}
        {/* <Review /> */}
        <Team />

        {/* Blog section */}
        {/* <Blog /> */}

        {/* Contact section */}
        <Contact />

        {/* Footer section */}
        <Footer />

        {/* Switcher section */}
        <Switcher />
      </div>
    </>
  );
}
